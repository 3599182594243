import React from "react";
import Button from "@mui/material/Button";
import "../../styles/main.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { patch, ApiError } from 'aws-amplify/api';
import { CircularProgress } from "@mui/material";

const DynamoDbTable = (props) => {
  //const data = props.props[0];
  const data = props.props[0];
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(data.disabled === true);

  function goToEditPage() {
    navigate("/editUserDetails", { state: { userDetails: data } });
  };

  async function disableUser() {
    setIsSubmitting(true);
    const isGood = await updateUser(true);
    if(isGood) {
      setIsDisabled(false);
    }
    setIsSubmitting(false);
  }

  async function enableUser() {
    setIsSubmitting(true);
    const isGood = await updateUser(false);
    if(isGood) {
      setIsDisabled(false);
    }
    setIsSubmitting(false);
  }

  async function updateUser(isEnabled) {
    const apiName = "users";
    const path = "/users/updateAppUser";
    const requestBody = {
      pk: data.pk,
      sk: data.sk,
      item: 'disabled',
      itemVal: isEnabled

    };
    const requestHeaders = {
      "Access-Control-Allow-Origin": "*",
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,PATCH',
    };
    
    try {
      const restOperation = patch({
        apiName: apiName, 
        path: path, 
        options: {
          headers: requestHeaders,
          body: requestBody,
        }
      });
      await restOperation.response;
      alert("We updated the user!");
      return true;
    } catch (e) {
      alert("ERROR!!!!! There was an error and we did not update the user. See console (F12) and contact justin");
      console.log('The error is below');
      console.log(e);
      if (e instanceof ApiError) {
        if (e.response) {
            const { 
            statusCode, 
            body 
            } = e.response;
            console.error(`Received ${statusCode} error response with payload: ${body}`);
        }
      }
      return false;
    } 
  }

  return (
    <form className="dynamo-form">
      <table className="styled-table">
        <thead>
          <tr>
            <td colSpan="2">
              <b>
                <u>Addventure DB Information</u>
              </b>
            </td>
            <td>
              {isSubmitting === true && (
                  <center><CircularProgress size={30} /></center>
              )}
              {isSubmitting !== true && (         
              <center>
                {!isDisabled && (
                  <Button variant="contained" color="error" onClick={disableUser}>
                    Disable
                  </Button>
                )}
                {isDisabled && (
                  <Button variant="contained" color="primary" onClick={enableUser}>
                    Enable
                  </Button>
                )}
              </center>
            )}
            </td>
            <td>
            <center>
              <Button variant="contained" color="primary" onClick={goToEditPage}>
                Edit
              </Button>
            </center>
              
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Name: </b>
              {data.name}
            </td>
            <td colSpan="2">
              <b>Company: </b>
              {data.pk}
            </td>
          </tr>
          <tr>
            <td rowSpan="3">
              {data.address1 != null ? data.address1 : "No Address 1"}
              <br />
              {data.address2}
              <br />
              {data.city}, {data.state} {data.zip}
            </td>
            <td>
              <b>ID: </b>
              {data.addventureId}
            </td>
            <td>
              <b>EMP Code: </b>
              {data.empCode}
            </td>
          </tr>
          <tr>
            <td>
              <b>Gender: </b>
              {data.gender}
            </td>
            <td>
              <b>Birthdate: </b>
              {data.birthDate}
            </td>
          </tr>
          <tr>
            <td>
              <b>Balance: </b>${data.accountBalance}
            </td>
            <td>
              <b>EE Contribution: </b>${data.ee_contribution}</td>
          </tr>
          <tr>
            <td>
              <b>Department: </b>
              {data.department}
            </td>
            <td>
              <b>Division: </b>
              {data.division}
            </td>
            <td>
              <b>Location: </b>
              {data.location}
            </td>
          </tr>
          <tr>
            <td>
              <b>Prime EE ID: </b>
              {data.primeEeId}
            </td>
            <td>
              <b>Relationship: </b>
              {data.relationship}
            </td>
            <td>
              <b>Active? </b>
              {data.activeStatus}
            </td>
          </tr>
          <tr>
            <td>
              <b>EE No: </b>
              {data.eeNo}
            </td>
            <td>
              <b>ER No:</b>
              {data.erNo}
            </td>
            <td>
              <b>Hire Date: </b>
              {data.hireDate}
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default DynamoDbTable;
