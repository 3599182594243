import React, { useState, useEffect } from "react";
import Papa from "papaparse";

import "../styles/main.scss";
import Sidebar from "../components/sidebar";
import DragDropFile from "../components/drag_drop_file";
import { useSelector } from "react-redux";
import { patch, post, ApiError } from 'aws-amplify/api';

function checkHeader(columns) {
  if (columns.length !== 4) {
    return `There are not 4 columns. This file is not valid because it had ${columns.length}`;
  }
  if (columns[0] !== "EEID") {
    return `First column must be EEID. Your header in first column is: ${columns[0]}`;
  }
  if (columns[1] !== "Payroll Identifier") {
    return `Second column must be 'Payroll Identifier'. Your header in second column is: ${columns[1]}`;
  }
  if (columns[2] !== "Plan Year") {
    return `Third column must be 'Plan Year'. Your header in third column is: ${columns[2]}`;
  }
  if (columns[3] !== "Payroll Contribution Amount") {
    return `Fourth column must be 'Payroll Contribution Amount'. Your header in fourth column is: ${columns[3]}`;
  }
  return null;
}

function parseData(data) {
  const dummyArr = [];
  const eachLine = data?.toString().split("\r\n");
  eachLine?.forEach((line) => {
    const arr = line.split(",");
    dummyArr.push(arr);
  });
  dummyArr.shift(); //remove header row
  return dummyArr;
}

function checkFileBody(body) {
  console.log('Inside checkFileBody...');
  if (body.length > 100) {
    return `The file is too large. Max data rows is 100 and you gave ${body.length} rows. Please fix`;
  }
  for (let i = 0; i < body.length; i++) {
    if (body[i].length !== 4) {
      return `The length of a row is not the required 4, but ${
        body[i].length
      } instead. Row: ${i + 1}.`;
    }
    if (body[i][0].length < 3) {
      return `The minimum length for an EEID is 3. You entered ${
        body[i][0].length
      } on row ${i + 1}.`;
    }
    if (body[i][1].length < 2) {
      return `The minimum length for an 'Payroll Identifier' is 2. You entered ${
        body[i][1].length
      } on row ${i + 1}.`;
    }
    if (body[i][3].length < 1 || body[i][3].trim().length < 1) {
      return `The 'Payroll Contribution Amount' is empty on row ${i + 1}.`;
    }
    if (isNaN(body[i][3])) {
      return `The 'Payroll Contribution Amount' is not a number on row ${
        i + 1
      }.`;
    }
  }
  console.log('The file body is checked and good. Returning null...');
  return null;
}

function checkReturnedEeIdData(arrEeId, dynamoData, setErrorArray) {
  console.log('Start of checkReturnedEeIdData...');
  let arrTemp = [];
  if (arrEeId.length === 0) {
    setErrorArray([
      "No data was returned from dynamoDb. Are the users entered correctly?",
    ]);
    return;
  }
  for (let i = 0; i < arrEeId.length; i++) {
    const match = dynamoData.find((elt) => elt.eeNo === arrEeId[i]);
    if (match == null || match === undefined) {
      arrTemp.push(`The ${arrEeId[i]} does not exist in dynamoDB`);
    } else {
      if (match.pk === "NotEntered") {
        arrTemp.push(
          `${arrEeId[i]} has not completed the enrollment portal yet and is not in WEX. Cannot continue.`
        );
      }
    }
  }
  setErrorArray(arrTemp);
  console.log('We are returning from checkReturnedEeIdData and the retVal is: ' + (arrTemp.length === 0));
  return arrTemp.length === 0;
}

function getMoneyDescription(str) {
  if (str === "ER") {
    return "Employer Contribution";
  } else if (str === "EE") {
    return "Employee Contribution";
  } else {
    return str;
  }
}

function getWexMoneyDescription(str) {
  if (str === "ER") {
    return "Employer Contribution";
  }
  return "Payroll Deduction";
}

//They require date to be MMDDYYYY
function getWexDate() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear();
  const strDate = `${month}${day}${year}`;
  return strDate;
}

//Wex wants it in HHMMSS
function getWexTime() {
  const newDate = new Date();
  const hour = newDate.getHours().toString().padStart(2, "0");
  const minutes = newDate.getMinutes().toString().padStart(2, "0");
  const seconds = newDate.getSeconds().toString().padStart(2, "0");

  return `${hour}${minutes}${seconds}`;
}

function getTwoDecimalAmount(str) {
  return parseFloat(str).toFixed(2);
}

function getMoneyBodyData(dynamoData, fileBody, empCode, planName) {
  let arrTemp = [];
  let arrWex = [];
  let msEpoch = Date.now();
  console.log('We are starting getMoneyBodyData function');
  for (let i = 0; i < fileBody.length; i++) {
    console.log('Inside for loop in getMoneyBodyData. Iteration: ' + i);
    const dynamoRow = dynamoData.find(
      (elt) => elt.eeNo === `${empCode}#${fileBody[i][0]}`
    );
    const row = {
      pk: dynamoRow.pk,
      sk: dynamoRow.sk,
      amt: Number(fileBody[i][3]),
      desc: getMoneyDescription(fileBody[i][1]),
      milliseconds: msEpoch++,
    };
    const wexRecord =
      "CT|" + //Record Type - always CT
      `${dynamoRow.addventureId}|` + //Participant File ID - addventure ID
      `${planName}|` + //Plan Name
      `${getWexDate()}|` + //Contribution Date - MMDDYYYY
      `Employer Contribution|` + //Description - hardcoded per WEX
      `${getTwoDecimalAmount(fileBody[i][3])}|` + //Amount
      "Actual|" + //Amount Type - Employer Contribution
      "|" + //Tax Year - not required
      "|" + //Notes - not required
      "|" + //Plan Year start date - not required
      "|" + //Plan year end date - not required
      "|" + //Last name - not required
      "|" + //First name - not required
      `${getWexMoneyDescription(fileBody[i][1])}`; //Contribution label
    arrTemp.push(row);
    console.log('The row is below');
    console.log(row);
    arrWex.push(wexRecord);
    console.log('The wexRecord is below:');
    console.log(wexRecord);
  }
  const data = { moneyBody: arrTemp, wexArr: arrWex };
  console.log('We are finished with getMoneyBodyData and returning. Data is below');
  console.log(data);
  return data;
}

async function issueMoneyNetworkCall(moneyBody) {
  console.log('We are inside issueMoneyNetworkCall...');
  const apiName = "users";
  const path = "/users/money";
  const requestBody = { data: moneyBody };
  const requestHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  try {
    console.log('About to issue a PATCH to ' + path);
    const restOperation = patch({
      apiName: apiName, 
      path: path, 
      options: {
        body: requestBody,
        headers: requestHeaders,
      }
    });

    await restOperation.response;
    //if here we got a 2xx status code....
    console.log('The money network call was successful');
    return true;
  } catch (e) {
    console.log('We hit the catch block on issueMoneyNetworkCall');
    console.log(e);
    alert("We hit an error in issueMoneyNetworkCall. Please look at console (F12) and contact justin");
    return false;
  }
}

function _getFooter(empCode, wexDate, wexTime, numRecords) {
  console.log('Inside _getFooter....');
  const footer =
    "FF|" + //FF for file footer
    `${numRecords}|` + //record count
    "034|" + //administrative code - always 034 for Addventure
    `${empCode}|` + //emp code
    `${wexDate}|` + //Date submitted
    `${wexTime}` + //Time submitted
    "";
    console.log('Returning from _getFooter. Footer is below');
    console.log(footer);
  return footer;
}
function _getHeader(empCode, wexDate, wexTime) {
  console.log('Inside _getHeader...');
  const header =
    "FH|" + //FH for file header
    "034|" + //034 is the Addventure code - same for all
    `${empCode}|` + //employer code
    "N|" + //synchronize the data? Always N for No
    `${wexDate}|` + //submitted date (now) MMDDYYYY
    `${wexTime}|` + //time submitted (now) HHMMSS
    "3.5"; //API version for Wex - always this value
    console.log('Returning the header. It is below');
    console.log(header)
  return header;
}

function getWexFileContents(arrWex, empCode) {
  console.log('We are inside getWexFileContents...');
  const wexDate = getWexDate();
  console.log('Wex Date is: ' + wexDate);
  const wexTime = getWexTime();
  console.log('Wex time is: ' + wexTime);
  const header = _getHeader(empCode, wexDate, wexTime);
  console.log('Wex header is: ' + header);
  const footer = _getFooter(empCode, wexDate, wexTime, arrWex.length);
  console.log('Wex footer is: ' + footer);
  const body = arrWex.join("\r\n");
  console.log('The wex body is joined! ');
  const contents = `${header}\r\n${body}\r\n${footer}`;
  console.log('Data is fully retrieved. Contents are below');
  console.log(contents);
  console.log('Returning from getWexFileContents function as it is done');
  return contents;
}

async function sendFileToWex(arrWex, empCode, setFinishedText) {
  const apiName = "users";
  const path = "/wexfiles";
  const fileContents = getWexFileContents(arrWex, empCode);
  console.log('Start of sendFileToWex function...');
  const requestBody = {
    fileContents: fileContents,
    clientName: empCode,
  };
  const requestHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  try {
    console.log('About to issue a POST call to ' + path);
    const restOperation = post({
      apiName: apiName, 
      path: path, 
      options: {
        body: requestBody,
        headers: requestHeaders,
      }
    });

    await restOperation.response;
    //if here is is a 2xx status code and we are good!
    console.log('Return from wex was good. ');
    setFinishedText("We are finished!");
    return true;
  } catch (e) {
    alert("We hit an error sending the file to WEX. Check the console please (F12) and talk to justin ");
    console.log(e);
    console.log('The request headers are below');
    console.log(requestHeaders.toString());
    console.log('The request body is below that failed');
    console.log(requestBody.toString());
    console.log('Error above is from sendFileToWex in upload_pay.js');
    
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return false;
  }
}

async function issueEeIdNetworkCall(
  arrEeId,
  setErrorArray,
  fileBody,
  empCode,
  planName,
  setFinishedText
) {
  console.log('Beginning of issueEeIdNetworkCall');
  const apiName = "users";
  const path = "/users/money";
  const requestBody = { data: arrEeId };
  const requestHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  try {
    console.log('About to issue POST network call to issueEeNetworkCall for path: ' + path);
    const restOperation = post({
      apiName: apiName, 
      path: path, 
      options: {
        body: requestBody,
        headers: requestHeaders,
      }
    });

    const {body} = await restOperation.response;
    console.log('Body is below');
    console.log(body);
    const jsonResponse = await body.json();
    console.log('json response is below');
    console.log(jsonResponse);
    const json = jsonResponse;

    console.log('POST network call to ' + path + ' was successful with a 200. Continuing....');
    if (json.data == null || json.data.length === 0) {
      console.log(json);
      alert(
        `There was no data returned. Are the users added already? Check the console (F12).`
      );
      console.log('The was no data returned. Erroring out and returning false.');
      return false;
    } else {
      console.log('We have data returned!');
      const data = json.data;
      console.log('About to checkReturnedEeIdData...');
      const isSuccess = checkReturnedEeIdData(arrEeId, data, setErrorArray);
      console.log('Returned from checkReturnedEeIdData and result is: ' + isSuccess);
      if (isSuccess) {
        console.log('About to call getMoneyBodyData function...');
        const processedData = getMoneyBodyData(
          data,
          fileBody,
          empCode,
          planName
        );
        console.log('Returned back from getMoneyBodyData... Calling issueMoneyNetworkCall');
        const wasMoneyUploaded = await issueMoneyNetworkCall(
          processedData.moneyBody
        );
        console.log('Back from issueMoneyNetworkCall. WasSuccessful? ' + wasMoneyUploaded);
        if (wasMoneyUploaded) {
          console.log('It was a success so sending to WEX...');
          await sendFileToWex(processedData.wexArr, empCode, setFinishedText);
        }
      }
      console.log('We are finishing this. Returning isSuccess of: ' + isSuccess);
      return isSuccess;
    }

  } catch (e) {
    alert('We hit an error in issueEeIdNetworkCall. Check console (F12) and contact justin');
    console.log(e);
    alert("We hit an error fetching the EeIds. Error is above ");
    if (e instanceof ApiError) {
      if (e.response) {
          const { 
          statusCode, 
          body 
          } = e.response;
          console.error(`Received ${statusCode} error response with payload: ${body}`);
      }
    }
    return false;
  }
}

async function getEeIdData(
  body,
  empCode,
  planName,
  setErrorArray,
  setFinishedText
) {
  console.log('Start of getEeIdData');
  let arrEeId = [];
  for (let i = 0; i < body.length; i++) {
    arrEeId.push(`${empCode}#${body[i][0]}`);
  }
  const uniqueEeId = Array.from(new Set(arrEeId));
  const isSuccessful = await issueEeIdNetworkCall(
    uniqueEeId,
    setErrorArray,
    body,
    empCode,
    planName,
    setFinishedText
  );
  console.log('End of getEeIdData. IsSuccessful: ' + isSuccessful);
  return isSuccessful;
}

async function checkFile(
  data,
  columns,
  empCode,
  planName,
  setErrorArray,
  setFinishedText
) {
  console.log('Start of checkFile function');
  const headerCheck = checkHeader(columns);
  if (headerCheck !== null) {
    setErrorArray([headerCheck]);
    return false;
  }
  const body = parseData(data);
  const bodyCheck = checkFileBody(body);
  if (bodyCheck !== null) {
    setErrorArray([bodyCheck]);
    console.log('Error in isFileGood. Returning false');
    return false;
  }
  const isFileGood = await getEeIdData(
    body,
    empCode,
    planName,
    setErrorArray,
    setFinishedText
  );
  console.log('End of checkFile. IsFileGood: ' + isFileGood);
  return isFileGood;
}

const UploadPayFile = () => {
  const [upFiles, setUpFiles] = useState();
  //parsed data is below
  const [data, setData] = useState([]);
  const comp = useSelector((state) => state.companies);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [errorArray, setErrorArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [finishedText, setFinishedText] = useState("");

  useEffect(() => {
    if (upFiles === null || upFiles === undefined) {
      return;
    }
    if (upFiles.length > 1) {
      alert("You can only upload 1 file at a time. Please try again");
      return;
    }
    if (upFiles[0]?.name?.endsWith(".csv") !== true) {
      alert("You can only upload csv files");
      return;
    }
    if (
      selectedCompany == null ||
      selectedCompany === undefined ||
      selectedCompany.length < 1
    ) {
      alert("You must select a company before uploading a file.");
      return;
    }
    setErrorArray([]);
    setIsLoading(true);
    setFinishedText("");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = Object.keys(parsedData[0]);
      const emp_code = comp.companies.find(
        (elt) => elt.sk === selectedCompany
      ).emp_code;
      const planName = comp.companies.find(
        (elt) => elt.sk === selectedCompany
      ).planName;
      const isFileGood = await checkFile(
        reader.result,
        columns,
        emp_code,
        planName,
        setErrorArray,
        setFinishedText
      );
      setIsLoading(false);
      setData(reader.result);
    };
    reader.readAsText(upFiles[0]);
    setUpFiles(null); //reset for next upload - file must change name if not
  }, [upFiles]);

  const Select = ({ label, id, children, ...rest }) => (
    <div>
      <label htmlFor={id}>{label}</label>
      <select id={id} {...rest} onChange={companyChanged}>
        {children}
      </select>
    </div>
  );

  const companyChanged = (val) => {
    if (val.target.value != null && val.target.value.length > 0) {
      setSelectedCompany(val.target.value);
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main>
        <div className="topRow">
          <Select label="" id="company" name="company" required>
            <option value="">Select a company...</option>
            {comp.companies &&
              comp.companies.map((x, y) => <option key={x.sk}>{x.sk}</option>)}
          </Select>
        </div>
        <hr />
        <h1>
          <center>Drop Deposits File Below</center>
        </h1>
        <h4>
          <center>{selectedCompany}</center>
        </h4>
        {isLoading === false && <DragDropFile setUpFiles={setUpFiles} />}
        <br />
        <hr />
        {errorArray &&
          errorArray.map((elt, i) => {
            return (
              <center key={i}>
                <b style={{ color: "#f36c21" }}>ERROR! </b>
                {elt}
              </center>
            );
          })}
        {isLoading && (
          <center>
            <b style={{ color: "#f36c21" }}>Working! </b>Please do not navigate
            away from this page
          </center>
        )}
        {finishedText && (
          <center>
            <b style={{ color: "#f36c21" }}>SUCCESS! </b>The records have been
            updated!
          </center>
        )}
      </main>
    </div>
  );
};

export default UploadPayFile;
